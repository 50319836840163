import { useState, useEffect, useContext, useCallback } from "react";
import { useLazyQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { SET_USER } from "../../store/actions";

import { GlobalContext } from "../../views/App";

const meGQL = gql`
  query me {
    me {
      email
      role {
        name
        usedFor
      }
      verified
      fullName
      pictureURL {
        url
      }
      sex
      country
      id
      beneficiaryOf
      phoneNumber
      openFireUsername
      openFirePassword
      belongsToCompany {
        id
        companyPlan {
          id
        }
      }
      plans {
        plan {
          id
          name
          features {
            name
            disabled
          }
        }
      }
      appointmentMD {
        id
        reasonForConsultation
      }
      appointmentIC {
        id
        reasonForConsultation
      }
    }
  }
`;

const UseLogin = (verifyInMount = false) => {
  const [firtsVerified, setFirtsVerified] = useState(false);
  const { state, dispatch } = useContext(GlobalContext);
  const [isLogin, setIsLogin] = useState(false);
  const [getUserInfo, { loading, data, error }] = useLazyQuery(meGQL, {
    fetchPolicy: "no-cache",
    notifyOnNetworkStatusChange: true,
  });

  const setUserInContext = useCallback(
    (newUserData) => {
      if (!firtsVerified) {
        setFirtsVerified(true);
      }
      dispatch({
        type: SET_USER,
        payload: newUserData,
      });
    },
    [dispatch, firtsVerified]
  );

  useEffect(() => {
    if (verifyInMount) {
      getUserInfo();
    }
  }, [verifyInMount, getUserInfo]);

  useEffect(() => {
    if (!loading) {
      if (error) {
        setIsLogin(false);
        setUserInContext(null);
      }
      if (data) {
        setUserInContext(data.me);
        setIsLogin(true);
      }
    }
  }, [loading, data, error, setUserInContext]);

  const refetchUser = () => {
    try {
      getUserInfo();
    } catch (e) {
      setIsLogin(false);
      setUserInContext(null);
    }
  };

  return {
    isLogin,
    loading: verifyInMount && !firtsVerified ? true : loading,
    refetchUser,
    roleUser: state.user ? state.user.role.name : null,
    roleGroup: state.user ? state.user.role.usedFor : null,
  };
};

export default UseLogin;
