import dotenv from "dotenv";
dotenv.config();

export const HOST = window.location.host;

export const NODE_ENV = process.env.REACT_APP_NODE_ENV;
export const LOCAL_HOST = "localhost:3000";
export const DEFAULT_VIEW_USER = "/admin/dashboard";
export const DEFAULT_VIEW_ADMIN = "/md/home";
export const LANDING_URL = process.env.REACT_APP_LANDING_URL;
export const URI = process.env.REACT_APP_APOLLO_URI;
export const COMPANY_NAME = process.env.REACT_APP_COMPANY_NAME;
export const COMPANY_LOGO = process.env.REACT_APP_COMPANY_LOGO;
